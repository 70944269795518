.topRowWrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: left;
  // align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  margin-bottom: 12px;
}

.gridSelectionWrapper {
  min-height: 6.8vh;
  background-color: #213448;
  color: #fff;
  padding: 3px 12px;
  font-family: monospace;
  font-size: 1.6vh;
  z-index: 1;
  border-radius: 4px;
  margin-top: 1.6vh;
  margin-left: 2vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  box-shadow: 5.67px 5.67px 5.66px rgba(0, 0, 0, 0.75);
}

// ***** new grid selection css ******
.gridIconCard {
  min-height: 6.8vh;
  margin-top: 1.6vh;
  margin-left: 2vw;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #213448;
  color: #fff;
  border-radius: 4px;
  font-family: monospace;
  font-size: 1.6vh;
  z-index: 1;
  box-shadow: 5.67px 5.67px 5.66px rgba(0, 0, 0, 0.75);
  gap: 10px;

  span {
    padding: 0px 10px;
  }

  .gridIconContainer {
    width: 100%;
    margin: 3px 0px 3px 0px;
    box-sizing: border-box;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #213448;
    gap: 10px;

    .iconCard {
      width: 2.1vw;
      height: 2.1vw;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid #213448;
      border-radius: 50%;
      cursor: pointer;

      .icon {
        width: 1.95vw;
        height: 1.95vw;
      }
    }
    .iconCard.active {
      border: 0.15vw solid white;
    }
  }
}

.iconAndSeparator {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.verticalLine {
  width: 2px;
  height: 36px;
  background-color: #b6efea;
  margin-left: 4px;
  width: 0.106em;
}

.rowItems {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

.time {
  margin-right: 24px;
}

.verticalLine {
  width: 0.106em;
  height: 34px;
  background-color: #b6efea;
  margin-left: 16px;
  margin-right: 8px;
}
