@import "../../style.scss";
.navbar {
  @include themify($themes) {
    width: 100%;
    height: 60px;
    position: fixed;
    top: 0;
    left: 0;
    background-color: themed("primaryBlue");
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0px 20px 0px 20px;
    z-index: 99;

    // @include mobile {
    //   padding: 0px 20px 0px 20px;
    // }

    // @include mobile-landscape {
    //   padding: 0px 30px 0px 30px;
    // }
    // @include desktop {
    //   padding: 0px 90px 0px 90px;
    // }
    // @include large-desktop {
    //   padding: 0px 200px 0px 200px;
    // }
    // @include extra-large-desktop {
    //   padding: 0px 330px 0px 330px;
    // }

    .logoContainer {
      box-sizing: border-box;
      width: 50px;
      height: 50px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      img {
        width: 45px;
        height: 45px;
      }
    }
    .menuContainer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      list-style-type: none;

      .profile {
        width: 45px;
        height: 45px;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        color: themed("primaryBlue");
        border-radius: 50%;
        overflow: hidden;
        font-size: 25px;
      }

      .btn{
        box-sizing: border-box;
        padding: 8px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        border-radius: 5px;
        border: 1px solid themed('primaryBlue');
        background-color: themed('primaryBlue');
        color: white;
        font-size: 18px;
        cursor: pointer;
        .icon{
          width: 25px;
          height: 25px;
        }
      }
      .btn:hover{
        background-color: white;
        color: themed('primaryBlue');
      }
    }
  }
}
