// @import "../../style.scss";
// .mapVisualizer{
//     @include themify($themes){
//         width: 100%;
//         height: 100dvh;
//     }
// }

// .map-container {
//   height: 100%;
//   width: 100%;
// }

.toDemoWrapper{
  background-color: rgb(35 55 75 / 90%);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  border-radius: 4px;
}

.scenarioSelectionWrapper{
  background-color: rgb(35 55 75 / 90%);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 80px;
  margin-left: 12px;
  border-radius: 4px;
}

.toDemoBtn {
  width: 150px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: 5px;
  background-color: themed("primaryBlue");
  border: 1px solid themed("primaryBlue");
  color: rgb(35 55 75 / 90%); 
  font-size: 15px;
  cursor: pointer;
}

.toScenarioBtn{
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: 5px;
  background-color: themed("primaryBlue");
  border: 1px solid themed("primaryBlue");
  color: rgb(35 55 75 / 90%); 
  font-size: 15px;
  cursor: pointer;
}

.scenarioPicker{
  margin-bottom: 16px;
}

.animationPicker{
  margin-bottom: 16px;
}

.vubEvergiLogoWrapper{
  z-index: 3;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 2vw;
  margin-right: 12px;
}