.sidebar {
    background-color: rgb(35 55 75 / 90%);
    color: #fff;
    padding: 6px 12px;
    font-family: monospace;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    // margin: 12px;
    border-radius: 4px;
    margin-top: 12x;
    margin-left: 30px;
}

.dropDownContainer{
    z-index: 200;
    position: absolute;
    left: 0;
    margin-top: 60px;
    margin-left: 12px;
    font-size: 15px;
}

.toggleFillBtnWrapper{
  z-index: 200;
  position: absolute;
  left: 0;
  margin-top: 90px;
  margin-left: 12px;
  font-size: 15px;
  color: white;
}

.controlsWrapper{
  background-color: rgb(35 55 75 / 90%);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 200px;
  margin-top: 60px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 68px;

  padding-top: 10px;
  padding-right: 6px;
  padding-bottom: 10px;
  padding-left: 6px;
  border-right: solid 1px black;
}

.nextBtn{
  cursor: "pointer";
}

.stopStartBtn{
  cursor: "pointer";
}

select {
    /* Styles for the select element */
    font-size: 14px;
    font-family: monospace;
  }

option {
    /* Styles for the option elements */
    color: #333;
    font-size: 14px;
    font-family: monospace;
  }


// .map-container {
//     height: 100%;
//     width: 100%;
//   }
  

.mapboxgl-popup {
    /* max-width: 100px; */
    font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
    z-index: 110;
  }