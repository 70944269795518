@import "../../../style.scss";
.appBar{
    @include themify($themes){
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;

        .titleHolderPage{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .title{
                font-size: 20px;
            }
        }

        .titleHolderAction{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;

            .title{
                font-size: 20px;
            }
        }

        .actionBtn {
            width: 150px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            border-radius: 5px;
            background-color: themed("primaryBlue");
            border: 1px solid themed("primaryBlue");
            color: white;
            font-size: 15px;
            cursor: pointer;
    
            .icon {
              width: 22px;
              height: 22px;
            }
          }

          .iconBtnHolder {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
    
            .iconBtn {
              width: 25px;
              height: 25px;
            }
          }
          .iconBtnHolder:hover {
            cursor: pointer;
            background-color: themed("primaryBlue");
          }
    }
}