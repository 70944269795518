.iconHolder{
  background-color: rgb(35 55 75 / 90%);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 300;
  position: absolute;
  margin-left: 300px;
  margin-top: 12x;
}

.coordinatesbar {
    background-color: rgb(35 55 75 / 90%);
    color: #fff;
    padding: 6px 12px;
    font-family: monospace;
    z-index: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    // margin: 12px;
    border-radius: 4px;
    margin-bottom: 10px;
    margin-left: 100px;
}

.dropDownContainer{
    z-index: 200;
    position: absolute;
    left: 0;
    margin-top: 60px;
    margin-left: 12px;
    font-size: 15px;
}

.controlButtonsWrapper{
  background-color: #213448;
  padding: 0.3vw;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 2vw;
  margin-top: 13vh;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color:#B6EFEA;
  box-shadow: 5.67px 5.67px 5.66px rgba(0, 0, 0, 0.75);
}

.nextBtn{
  cursor: "pointer";
  width: 1.9vw;
  height: 1.9vw;
  font-size: 1.9vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 0.4vw;
}

.stopStartBtn{
  cursor: "pointer";
  border-right: 1px solid #B6EFEA;
  width: 1.9vw;
  height: 1.9vw;
  font-size: 1.9vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-right: 0.4vw;
}


// .map-container {
//     height: 100%;
//     width: 100%;
//   }

.vubEvergiLogoMapWrapper{
  z-index: 3;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 2vw;
  margin-right: 12px;
}