@import "../../style.scss";
.scenarioManager {
  @include themify($themes) {
    width: 100%;
    height: 100vh;
    // position: relative;
    padding: 0px 0px 20px 0px;
    box-sizing: border-box;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;

    
    .listContainer {
      flex: 1;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      overflow: auto;
      border: 1px solid themed('primaryBlue');
      border-radius: 5px;

      .loadingContainer{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        
        img{
          width: 300px;
          height: 300px;
        }
      }

    }
  }
}
