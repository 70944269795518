@import "../../../../style.scss";
.fileContainer {
  @include themify($themes) {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;

    .title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 20px;
    }
    .files{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 10px;
    }
  }
}
