$themes:(
    dark: (
        bg: #0f0f1f,
        primaryText: white,
        primaryBorder: white,
        primaryBlue: #272740
    ),
);

@mixin themify($themes) {
    @each $theme, $map in $themes {
      .theme-#{$theme} & {
        $theme-map: () !global;
        @each $key, $submap in $map {
          $value: map-get(map-get($themes, $theme), "#{$key}");
          $theme-map: map-merge(
            $theme-map,
            (
              $key: $value,
            )
          ) !global;
        }
        @content;
        $theme-map: null !global;
      }
    }
  }
  
  @function themed($key) {
    @return map-get($theme-map, $key);
  }

 
  @mixin mobile {
    @media (min-width: 320px) and (max-width: 575.98px) {
      @content;
    }
  }
  
  @mixin mobile-landscape {
    @media (min-width: 576px)  and (max-width: 765px){
      @content;
    }
  }
  
  @mixin tablet {
    @media (min-width: 766px) and (max-width: 1024px){
      @content;
    }
  }
  
  @mixin desktop {
    @media (min-width: 1025px) {
      @content;
    }
  }
  @mixin large-desktop {
    @media (min-width: 1600px) {
      @content;
    }
  }
  @mixin extra-large-desktop {
    @media (min-width: 2100px) {
      @content;
    }
  }

  