.nodeInfoPanelWrapperTop{
  background-color: rgb(35 55 75 / 90%);
  color: #fff;
 
  font-family: monospace;
  z-index: 200;
  position: absolute;
  top: 0;
  left: 0;
  // margin: 12px;
  margin-left: 240px;
  margin-top: 60px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;

  padding-top: 10px;
  padding-right: 6px;
  padding-bottom: 10px;
  padding-left: 6px;
  font-size: 12px;
  height: 68px;
}

.nodeWrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: space-between;
}