.nodeInfoPanelWrapperTop{
  // background-color: rgb(35 55 75 / 90%);
  background-color: #213448;;
  color: #fff;
  box-shadow: 5.67px 5.67px 5.66px rgba(0, 0, 0, 0.75);
 
  font-family: monospace;
  z-index: 200;
  position: absolute;
  top: 0;
  left: 0;
  // margin: 12px;
  margin-left: 50vw;
  margin-top: 1.6vh;
  border-radius: 4px;
  display: flex;
  flex-direction: row;

  padding-top: 1.4vh;
  padding-right: 3px;
  padding-bottom: 1.4vh;
  padding-left: 6px;
  font-size: 1.6vh;
  min-height: 6.8vh;
}

.nodeName{
  margin-bottom: 0.45vh;
}

.projectionWrapper{
  width: 80px;
  height: 80px;
}

.nodeWrapper{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
  padding-left: 0.9vw;
  padding-right: 0.9vw;
}

.nodeAssetsWrapper{
  display: flex;
  flex-direction: row;
}

.openCloseButton {
  border-left: solid 1px #B6EFEA;
  cursor: pointer;
  color: #B6EFEA;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 0.3vw;
  padding-right: 0.3vw;
  font-size: 1.6vh;
}