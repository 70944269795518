@import "../../../style.scss";
.dataTable {
    @include themify($themes) {
      width: 100%;
      overflow: auto;
      border-collapse: collapse;
  
      .thead {
        tr {
          border-bottom: 1px solid themed("primaryBlue");
          .rowCount {
            box-sizing: border-box;
            padding: 5px;
            color: themed("primaryText");
            font-size: 15px;
            text-align: center;
          }
          .tableColumn {
            box-sizing: border-box;
            padding: 5px;
            color: themed("primaryText");
            font-size: 15px;
            font-weight: 600;
          }
        }
      }
  
      .tableBodyContainer {
        tr {
          border-bottom: 1px solid themed("primaryBlue");
          .index {
            text-align: center;
            border-right: 1px solid themed("primaryBlue");
          }
          td {
            box-sizing: border-box;
            padding: 10px 5px 10px 5px;
  
            .imgContainer{
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              .imgHolder{
                  width: 35px;
                  height: 35px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 5px;
                  overflow: hidden;
              }
            }
  
            .actionBtnContainer {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              .actionBtn {
                background-color: themed("primaryBlue");
                padding: 5px;
                width: 80px;
                height: 30px;
                border-radius: 5px;
                color: white;
                border: none;
                cursor: pointer;
              }
            }
          }
        }
        tr:hover{
          cursor: pointer;
          background-color: themed('primaryBlue');
        }
      }
    }
  }
  