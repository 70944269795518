@import "../../../style.scss";
.docDesign {
  @include themify($themes) {
    width: 150px;
    height: 200px;
    margin-bottom: 10px;
    .container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      border: 2px solid themed("primaryBlue");
      border-radius: 10px;
      justify-content: center;
      align-items: center;
      padding: 10px;
      box-sizing: border-box;
      cursor: pointer;
      .customHr {
        width: 100%;
        border: none;
        border-bottom: 2px dashed themed("primaryBlue");
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
    .container:hover{
        background-color: themed('primaryBlue');
        .customHr {
            width: 100%;
            border: none;
            border-bottom: 2px dashed themed("bg");
            margin-top: 5px;
            margin-bottom: 5px;
          }
    }
  }
}
