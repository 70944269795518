@import "../../../style.scss";
.scenarioInfo {
  @include themify($themes) {
    width: 100%;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    color: themed("primaryText");

    .infoContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 10px;
      box-sizing: border-box;
      .titleBar {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 25px;
        .name {
          font-size: 18px;
        }

        .btnContainer{
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 15px;

          .delBtn {
            width: 150px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            border-radius: 5px;
            background-color: #d04848;
            color: white;
            font-size: 15px;
            cursor: pointer;
  
            .icon {
              width: 18px;
              height: 18px;
            }
          }
          .editBtn {
            width: 150px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            border-radius: 5px;
            color: black;
            font-size: 15px;
            cursor: pointer;
  
            .icon {
              width: 18px;
              height: 18px;
            }
          }
        }

        
      }

      .label {
        font-size: 15px;
        margin-bottom: 10px;
        padding-left: 5px;
      }
      .description {
        max-width: 100%;
        display: flex;
        padding: 5px;
        // border: 1px solid themed('primaryBlue');
        // border-radius: 5px;
      }
    }
  }
}
