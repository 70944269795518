.generalInfoPanelWrapperBottom {
  background-color: #213448;
  font-family: monospace;
  z-index: 200;
  color: #fff;
  position: absolute;
  align-items: center;
  bottom: 0;
  left: 0;
  margin-left: 2vw;
  margin-bottom: 2vw;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  padding-top: 0.6vw;
  padding-right: 0px;
  padding-bottom: 0.6vw;
  padding-left: 0.2vw;
  // make sure open and closed panels are the same height for a better animation
  // min-height: 15vh;
  transform-origin: left center;
  transition: transform 0.3s ease-in-out;
  box-shadow: 5.67px 5.67px 5.66px rgba(0, 0, 0, 0.75);
  font-size: 1.6vh;

  &.closed {
    transform: scaleX(0.85);
  }

  &.open {
    transform: scaleX(1);
  }
}

// .generalInfoPanelWrapperTop {
//   background-color: rgb(35 55 75 / 90%);
//   color: #fff;
//   font-family: monospace;
//   z-index: 200;
//   position: absolute;
//   top: 0;
//   left: 0;
//   margin-top: 90px;
//   margin-left: 12px;
//   border-radius: 4px;
//   display: flex;
//   flex-direction: row;

// }

.measurementCardTitle{
  color: #B6EFEA;
  font-size: 1.6vh;
  padding-bottom: 0.7vh;
}

.closedGeneralPanelWrapper {
  display: flex;
  flex-direction: row;
  gap: 40px;
  // padding-right: 8px;
  border-right:  solid 1px #B6EFEA;
  justify-content: center;
  align-items: center;
  padding: 0.7vh;
}

.openGeneralPanelWrapper {
  display: flex;
  flex-direction: row;
  font-size: 1.6vh;
  height: 100%;
}

.openCloseButtonGeneralPanel {
  height:100%;
  cursor: pointer;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color:#B6EFEA;
  font-size: 1.6vh;
  padding-left: 0.3vw;
  padding-right: 0.3vw;
}

.measurementCardWrapper {
  // width:100%;
  padding-right: 0.9vw;
  padding-left: 0.9vw;
  border-right:  solid 1px #B6EFEA;
}

.measurement{
  display:flex;
  flex-direction:column;
  align-items: center;
  min-width: 5vw;
}

.measurementIconWrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.7vh;
}

.cardName {
  padding-bottom: 0px;
}

.valuesWrapper {
  display: flex;
  height:100%;
  flex-direction: row;
  text-align: center;
  // align-items: stretch;
  gap: 1.2vw;
}

.pollution,
.energy,
.monetary {
  display: flex;
  flex-direction: column;
  padding-right: 0.45vw;
  padding-left: 0.45vw;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.verticalLineInfoPanel {
  border-left: solid 1px #B6EFEA;
  // height: 100%;
  margin-left: 12px;
  margin-right: 12px;
}