@import "../../../style.scss";
.addScenarioContainer {
  @include themify($themes) {
    width: 100%;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    color: themed("primaryText");

  }
}
