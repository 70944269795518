@import "../../style.scss";
.login {
  @include themify($themes) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logoContainer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 20px;
      gap: 20px;

      img {
        width: 100px;
        height: 100px;

        @include mobile {
          width: 60px;
          height: 60px;
        }
      }
      .headerContainer {
        display: flex;
        flex-direction: column;
        .title {
          font-size: 38px;
          font-weight: bold;
          color: white;

          @include mobile{
            font-size: 28px;
          }
        }
      }
    }
    .cardTitle {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      span {
        font-size: 25px;
        color: white;

        @include mobile{
          font-size: 20px;
        }
      }
    }

    .formContainer{
      width: 100%;
      .inputHolder{
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
        span{
          box-sizing: border-box;
          padding-left: 2px;
          font-size: 15px;
          color: white;
          margin-bottom: 5px;

          .imp{
            color: red;
            font-size: 18px;
            font-weight: bold;
          }
        }

        input{
          box-sizing: border-box;
          width: 100%;
          height: 45px;
          border: 1px solid themed('primaryBorder');
          border-radius: 5px;
          background-color: themed('bg');
          padding: 5px;
          color: themed('primaryText');
          font-size: 18px;
        }
        input:focus{
          outline: none;
        }
  
      }

      .btn{
        width: 100%;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        border: 1px solid themed('primaryBlue');
        background-color: themed('primaryBlue');
        color: white;
        font-size: 20px;
        cursor: pointer;
      }
    }

    
  }
}
