.generalInfoPanelWrapperBottomDemo {
  background-color: rgb(35 55 75 / 90%);
  color: #fff;
  font-family: monospace;
  z-index: 200;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 30px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-right: 0px;
  padding-bottom: 10px;
  padding-left: 16px;
  // make sure open and closed panels are the same height for a better animation
  height:60px;
  transform-origin: left center;
  transition: transform 0.3s ease-in-out;

  &.closed {
    transform: scaleX(0.85);
  }

  &.open {
    transform: scaleX(1);
  }
}

.generalInfoPanelWrapperTop {
  background-color: rgb(35 55 75 / 90%);
  color: #fff;
  // padding: 6px 12px;
  font-family: monospace;
  z-index: 200;
  position: absolute;
  top: 0;
  left: 0;
  // margin: 12px;
  margin-top: 90px;
  margin-left: 12px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;

  padding-top: 10px;
  padding-right: 0px;
  padding-bottom: 10px;
  padding-left: 16px;
}

.closedGeneralPanelWrapper {
  display: flex;
  flex-direction: row;
}

.openGeneralPanelWrapper {
  display: flex;
  flex-direction: row;
  font-size: 12px;
}

.openCloseButton {
  border-left: solid 1px black;
  cursor: pointer;
}

.measurementCardWrapper {
  display: flex;
  flex-direction: column;
  padding-right: 12px;
}

.cardName {
  padding-bottom: 0px;
}

.valuesWrapper {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items:stretch;
}

.pollution, .energy, .monetary{
  display: flex;
  flex-direction: column;
  padding-right: 6px;
  flex:1;
}
