.legendContainer{
    background-color: rgb(35 55 75 / 90%);
    color: #fff;
    padding: 6px 12px;
    font-family: monospace;
    font-size: 1.6vh;
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 4px;
    margin-top: 1.6vh;
//     margin-left: 30px;
    margin-right: 12px;
}

.valueWrapper{
    display: flex;
    flex-direction: row;
    padding-top: 3px;
    align-items: center;
}

.color{
    width: 1.5vh;
    height: 1.5vh;
}

.explanation{
    padding-left: 6px;
}

.legendTitle{
    padding-bottom: 3px;

    .iconLegend{
        font-size: 2vh;
    }
}