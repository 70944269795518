@import "../../style.scss";
.homeContainer{
    @include themify($themes){
        width: 100%;
        height: 100dvh;
        display: flex;
        align-items: center;
        justify-content: center;
        
        .loginContainer{
            width: 400px;
            @include mobile{
                width: 100%;
                padding: 10px;
            }
        }
    }
}