@import "../../style.scss";
.scenarioForm {
  @include themify($themes) {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    box-sizing: border-box;

    .fileInputContainer {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;

      .card {
        width: 23%;
        height: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: themed("primaryBlue");
        border-radius: 10px;
        margin-bottom: 15px;

        cursor: pointer;

        @include mobile {
          width: 100%;
        }
        @include mobile-landscape {
          width: 48%;
        }

        .icon {
          width: 70px;
          height: 70px;
          color: grey;
          margin-bottom: 10px;
        }
        .label {
          font-size: 15px;
          color: gray;
        }
      }
    }

    .inputHolder {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 15px;

      span {
        font-size: 16px;
        color: themed("primaryText");
        margin-bottom: 5px;
      }
      input {
        width: 50%;
        height: 45px;
        background-color: themed("primaryBlue");
        box-sizing: border-box;
        padding: 5px;
        border: 1px solid themed("primaryBlue");
        border-radius: 5px;
        color: white;
        font-size: 16px;

        @include mobile {
          width: 100%;
        }
        @include mobile-landscape {
          width: 100%;
        }
        @include tablet {
          width: 100%;
        }
      }
      input:focus{
        outline: none;
      }
    }
    .textareaHolder {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;

      span {
        font-size: 16px;
        color: themed("primaryText");
        margin-bottom: 5px;
      }

      textarea {
        width: 100%;
        background-color: themed("primaryBlue");
        border: 1px solid themed("primaryBlue");
        border-radius: 5px;
        box-sizing: border-box;
        padding: 5px;
        color: white;
        font-size: 16px;
      }
      textarea:focus{
        outline:none;
      }
    }
    .btnContainer{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .holder1{
        display: flex;
        gap: 10px;

        button{
          width: 130px;
          height: 40px;
          background-color: white;
          border: 1px solid white;
          border-radius: 5px;
          cursor: pointer;
          font-size: 17px;
        }
      }
      .holder2{
        display: flex;

        button{
          width: 130px;
          height: 40px;
          background-color: #D04848;
          border: 1px solid #D04848;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          font-size: 17px;
          cursor: pointer;
        }
      }

    }
  }
}
